import React from "react";

import promocionAlarma1 from "../assets/img/familiaImage.png";
//import promocionAlarma1Mobile from "../assets/img/promoMobile.jpg";
import promocionAlarma1Mobile from "../assets/img/familiaImageMobile.png";

import "../assets/css/imgPanel.css";

export const ImgPanel = (img, img_mobile) => {
  //const text = "Recibe asesoramiento sin cargo"; //??
  const text = "Prosegur Alarmas"; //??

  return (
    <div className="panel-title">
      <div className="img-panel">
        <div className="img-panel-overlay"/>
              <img
                src={promocionAlarma1Mobile}
                alt={text}
                className="img-mobile"
              />
              <img
                src={promocionAlarma1}
                alt={text}
                className="img-desk"
              />
      </div>
    </div>
  );
};
