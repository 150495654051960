import React from 'react'
import "../assets/css/footer.css";
import logo from "../assets/img/logo_footer.png";
import PhoneFooter from "./PhoneFooter.js";


const Footer = (data) => {
  return (
    <div className='footer'>
      <div className="img-container-footer">
        <a href="#root">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <div className="call-footer">
        <PhoneFooter data={data.data}/>
      </div>

    </div>
  )
}

export default Footer
