import React from "react";
import logo from "../assets/img/logo.png";
import Floating from "./Floating.js";
//import phoneDesktop from "../assets/img/phoneDesktop.svg";
import "../assets/css/topBar.css";
//import { FormattedMessage } from "react-intl";

export const TopBar = (data) => {

  return (
    <div className="top-bar">
      <div className="img-container-top-bar">
        <a href="#root">
          <img src={logo} alt="logo" />
        </a>
      </div>
      <div className="call-top-bar">
        <Floating data={data.data}/>
      </div>
    </div>
  );
};
